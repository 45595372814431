/* eslint-disable no-restricted-imports */
import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Row } from "react-bootstrap";
import { Grid, Switch, Box, Popover, Badge, Button, Menu, MenuItem as ItemMenu, Typography, Toolbar, Fade, AppBar, Drawer, List, CssBaseline, Divider, Collapse, IconButton, ListItem, ListItemText, ListItemIcon, DialogTitle } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountCircle from '@material-ui/icons/AccountCircle';
import BasePage from '../../../../app/BasePage';
import { menu } from "./menu";
import { hasChildren } from "./utils";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import ScheduleIcon from '@material-ui/icons/Schedule';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ChangePassword from "../../../../app/modules/Auth/pages/ChangePassword";
import Swal from 'sweetalert2';
import { API } from "../../../../redux/setupAxios";
import moment from 'moment';
import { hubConnection } from '../../../../app/modules/Function/notification';
import { soundPlay, soundStop } from '../../../../app/modules/Function/global';
import { version } from '../../../../../package.json';

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: '50px'
  },
  appBar: {
    [theme.breakpoints.up("md")]:{
      width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    },
    width: '100%'
  },
  appBarMobile:{
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  humberger:{
    [theme.breakpoints.up("md")]:{
      display: 'none'
    },
  },
  humbergerHide:{
    display:'none'
  },
  menuButton: {
    marginRight: 6,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 'auto',
    [theme.breakpoints.down("sm")]:{
      display: 'none'
    },
  },
  drawerActive: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 'auto',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    marginTop: 75,
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]:{
      
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("xs")]:{
      
      marginTop: 182
    },
  },
  title: {
    flexGrow: 1,
  },
  drawerLogo: {
    height: 128
  }
}));

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const convertTime = (time) => {
  let hour = Math.floor(time / 60);
  let minute = time % 60
  if (hour < 10) {
    hour = '0' + hour
  }
  if (minute < 10) {
    minute = '0' + minute
  }
  return `${hour}:${minute}`
}

function MaterialDrawer(props) {
  const { dispatch, auth, location } = props;
  const { pathname } = location;
  const { user } = auth;
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorNotif, setAnchorNotif] = React.useState(null);
  const [value, setValue] = React.useState(user?.isTodayOpen);
  const [listNotif, setListNotif] = React.useState(auth.notification || []);
  const [openModal, setOpenModal] = React.useState(false);
  const [signOut, setSignOut] = React.useState(false);
  const [isBadge, setIsBadge] = React.useState(0);
  const [sideBar, setsideBar] = React.useState(false);
  const [soundOn, setSoundOn] = React.useState(null);
  const [typeModal, setTypeModal] = React.useState('');
  const [timeOutlet, setTimeOutlet] = React.useState({
    openTime: '',
    closeTime: ''
  });
  const latestChat = useRef(null);
  latestChat.current = listNotif;

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  };

  const handleNotif = event => {
    getNotif()
    setIsBadge(0)
    setAnchorNotif(event.currentTarget);
  };

  const handleCloseNotif = () => {
    setAnchorNotif(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onChangeSwitch = () => {
    if (value) {
      Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure to close this outlet',
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          setOpenOutlet(false)
        }
      })
    } else {
      setOpenOutlet(true)
    }
  }

  const setOpenOutlet = async (val) => {
    try {
      const payload = {
        "isOpenToday": val,
      }
      const response = await API('outlet.open', {
        data: payload
      })
      if (response.data.isSuccess && response.data.statusCode === 200) {
        if (val) {
          Toast.fire({
            icon: 'success',
            title: 'This Outlet is Open'
          })
          getUser()
        } else {
          Toast.fire({
            icon: 'warning',
            title: 'This Outlet is Closed'
          })
          getUser()
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: response.data.message
        })
      }
    } catch {
      Toast.fire({
        icon: 'error',
        title: 'Something wrong!'
      })
    }
  }

  const getDetailUser = async () => {
    try {
      const response = await API('auth.user')
      if (response.data.isSuccess && response.data.statusCode === 200) {
        dispatch({
          type: 'SET_USER',
          payload: {
            ...auth,
            user: response?.data?.payload || null
          }
        })
        setTimeOutlet({
          openTime: response?.data?.payload?.openTime,
          closeTime: response?.data?.payload?.closeTime
        })
        setOpenModal(true)
      } else {
        Toast.fire({
          icon: 'error',
          title: response.data.message
        })
      }
    } catch {
      Toast.fire({
        icon: 'error',
        title: 'Something wrong!'
      })
    }
  }

  const getUser = async () => {
    try {
      const response = await API('auth.user')
      if (response.data.isSuccess && response.data.statusCode === 200) {
        dispatch({
          type: 'SET_USER',
          payload: {
            ...auth,
            user: response?.data?.payload || null
          }
        })
        setValue(response?.data?.payload?.isTodayOpen)
      } else {
        Toast.fire({
          icon: 'error',
          title: response.data.message
        })
      }
    } catch {
      Toast.fire({
        icon: 'error',
        title: 'Something wrong!'
      })
    }
  }

  const getNotif = async () => {
    try {
      const response = await API('notification.getNotif', {
        query: { type: 0 }
      })
      if (response.data.isSuccess && response.data.statusCode === 200) {
        setListNotif(response?.data?.payload);
      } else {
        Toast.fire({
          icon: 'error',
          title: response.data.message
        })
      }
    } catch {
      Toast.fire({
        icon: 'error',
        title: 'Something wrong!'
      })
    }
  }

  const readMessage = async (res) => {
    try {
      const response = await API('notification.readNotif', {
        data: [res.notificationOutletId]
      })
      if (response.data.isSuccess && response.data.statusCode === 200) {
        getNotif()
        if (res.status === 'Completed') {
          history.push("/notify-histori")
        } else {
          if (res.methodOrder === 'Delivery') {
            history.push("/notify")
          } else if (res.methodOrder === 'Pickup') {
            history.push("/notify-pickup")
          }
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: response.data.message
        })
      }
    } catch {
      Toast.fire({
        icon: 'error',
        title: 'Something wrong!'
      })
    }
  }


  const renderOperational = () => {
    return (
      <div className="mr-10 ml-10 mb-5">
        <Row className="justify-content-center">
          <span style={{ fontWeight: 600, fontSize: 16 }}>{user?.outletName}</span>
        </Row>
        <Row className="mt-2 justify-content-center">
          <span style={{ fontWeight: 500, fontSize: 14, }}>{moment(new Date()).format('DD MMMM YYYY')}</span>
        </Row>
        <Row className="mt-5 justify-content-between align-items-center">
          <div style={{ padding: 3, borderRadius: 5, border: '1px solid #5AA859', backgroundColor: 'rgba(90, 168, 89, 0.1)' }}>
            <span style={{ fontWeight: 600, fontSize: 12, color: '#5AA859', marginLeft: 10, marginRight: 10 }}>OPEN</span>
          </div>
          <span style={{ fontWeight: 'bold', fontSize: 14, }}>{convertTime(timeOutlet.openTime)} - {convertTime(timeOutlet.closeTime)}</span>
        </Row>
      </div>
    )
  }

  const renderNotif = () => {
    const result = listNotif.filter(e => !e.isRead);
    return (
      <Box style={{ width: 250 }}>
        <div className="d-flex align-items-center justify-content-start">
          <span style={{ fontWeight: 'bold', margin: 10 }}>NOTIFIKASI</span>
        </div>
        <Divider light></Divider>
        {result && result.map((d, i) => {
          return (
            <ItemMenu
              key={i}
              style={{ backgroundColor: !d.isRead && 'rgba(228, 241, 254, 1)' }}
              onClick={() => {
                readMessage(d)
                localStorage.setItem('orderDetailId', d.orderDetailId)
                setAnchorNotif(null)
              }}>
              <ListItemIcon style={{ marginRight: '-15px' }}>
                {d.status === "IncomingOrder" ?
                  <SVG
                    style={{ width: 30, height: 30 }}
                    src={toAbsoluteUrl(
                      "/media/svg/shopping-bag.svg"
                    )}
                    className="align-self-center"
                  /> : d.status === "DriverFound" ?
                    <CheckCircle style={{ fontSize: 30, color: '#5AA859', textAlign: 'center' }}></CheckCircle>
                    : d.status === "DriverNotFound" ?
                      <Cancel style={{ fontSize: 30, color: '#F05454', textAlign: 'center' }}></Cancel>
                      : d.status === "Delivering" ?
                        <img
                          style={{ width: 30, height: 30 }}
                          src={toAbsoluteUrl(
                            "/media/svg/delivery.png"
                          )}
                          alt="logo"
                          className="align-self-center"
                        />
                        : d.status === "Completed" || d.status === "PickedUp" ?
                          <CheckCircle style={{ fontSize: 30, color: '#5AA859', textAlign: 'center' }}></CheckCircle>
                          : d.status === "PinPickUp" ?
                            <div style={{ display: 'flex', backgroundColor: '#5AA859', justifyContent: 'center', alignItems: 'center', width: 28, height: 28, borderRadius: '50%' }}>
                              <strong className="text-white">PIN</strong>
                            </div>
                            : null
                }
              </ListItemIcon>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex flex-column" style={{ width: 165, paddingRight: 5, }}>
                  <span style={{ fontWeight: 'bold', fontSize: 12, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{d.title}</span>
                  <span style={{ fontWeight: 600, fontSize: 10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{d.content}</span>
                  <span style={{ fontSize: 10, marginTop: 5 }}>{moment(moment.utc(d?.createdDt).toDate()).local().format('DD MMMM YYYY HH:mm:ss')}</span>
                </div>
                <ChevronRightIcon />
              </div>
            </ItemMenu>
          )
        }).filter((d, i) => i < 3)}
        <Divider light></Divider>
        <div className="d-flex align-items-center justify-content-center" onClick={() => {
          history.push('/notifikasi')
          setAnchorNotif(null)
        }}>
          <strong style={{ color: '#3A4E85', cursor: 'pointer', margin: 10 }}>Lihat Semua Notifikasi</strong>
        </div>
      </Box>
    )
  }

  const humbergerMenu = React.useCallback(async () => {
    setsideBar(!sideBar)
  }, [sideBar]);


  const getConnection = React.useCallback(async () => {
  //   const connection = hubConnection()
  //   connection.start().then(() => {
  //     connection.send('AddToOutlet', user?.outletId)
  //     connection.on('NewNotification', (type, title, message) => {
  //       setIsBadge(title.length)
  //       if (type === 'IncomingOrder') {
  //         setSoundOn({ ...title.length })
  //       }
  //     });
  //     connection.on('NewOpenStatus', (isOpen, title, content) => {
  //       window.location.reload();
  //     });
  //   }).catch((error) => {
  //   });
  //   connection.onclose(async () => {
  //     await connection.start().then(() => {
  //       connection.send('AddToOutlet', user?.outletId)
  //       connection.on('NewNotification', (type, title, message) => {
  //         setIsBadge(title.length)
  //         if (type === 'IncomingOrder') {
  //           setSoundOn({ ...title.length })
  //         }
  //       });
  //       connection.on('NewOpenStatus', (isOpen, title, content) => {
  //         window.location.reload();
  //       });
  //     })
  // });
  }, [signOut]);

  const getSound = React.useCallback(async ()=>{
    const response = await API('notification.getSound', {
      data: []
    })
    if (response.data.isSuccess && response.data.statusCode === 200) {
     if (response.data.payload.length !== 0){
       setSoundOn(response.data.payload.length)
       setIsBadge(response.data.payload.length)
       return 
     }
    //  if (response.data.payload.length === 0){
      //  soundStop()
    //  }

    }
    soundStop()
  }, [signOut])

  const soundInterval = () => {
    getSound()
    setInterval(() => {
        getSound()
    }, 10000);
   
  }



  const handleKeyDown = React.useCallback(async () => {
    getConnection()
    soundInterval()
  }, []);

  React.useEffect(() => {
    if (soundOn) {
      soundPlay()
      // setTimeout(() =>
      //   soundStop()
      //   , 180000)
    }
    return () => setSoundOn(null)
  }, [soundOn]);

  React.useEffect(() => {
    if (!signOut) {
      getConnection()
      soundInterval()
      getNotif()
      getUser()
      // window.addEventListener('mouseup', handleKeyDown);
    } else {
      // window.removeEventListener('mouseup', handleKeyDown);
      history.push('/logout')
      window.location.href = '/logout';
    }
  }, [signOut]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed" className={classes.appBar}
      >
        <Toolbar className="pt-2 d-flex flex-column pb-2 justify-content-center">
          <Grid container justify="flex-end" alignItems="center">
              <span className={classes.humberger}>
                  <button className="btn p-0 burger-icon mr-5" id="kt_header_mobile_toggle"
                      onClick={() => humbergerMenu()}>
                    <span/>
                  </button>
              </span>
          </Grid>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <div
                className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <Badge badgeContent={(listNotif && listNotif.filter(e => { return !e.isRead }).length) || isBadge} variant="dot" color="secondary">
                  <IconButton
                    onClick={handleNotif}
                    style={{ padding: 3 }}
                  >
                    <i className="text-dark fa fa-bell"></i>
                    <span className="pulse-ring" />
                  </IconButton>
                </Badge>
              </div>
              <Popover
                open={Boolean(anchorNotif)}
                anchorEl={anchorNotif}
                onClose={handleCloseNotif}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {renderNotif()}
              </Popover>
            </Grid>
            <Grid item>
              <Typography>{`Hello, `}<span style={{ fontWeight: 600 }}>{user?.outletName || 'Anonymous'}</span></Typography>
            </Grid>
            <Grid item style={{ paddingLeft: 8 }}>
              <IconButton
                style={{ padding: 5 }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle style={{ fontSize: 26 }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                TransitionComponent={Fade}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <ItemMenu onClick={() => {
                  handleClose()
                  setTypeModal('ChangePassword')
                  setOpenModal(true)
                }}>
                  <ListItemIcon style={{ marginRight: '-20px' }}>
                    <LockOpenIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Change Password" />
                </ItemMenu>
                <ItemMenu onClick={() => {
                  setSignOut(true)
                }
                }>
                  <ListItemIcon style={{ marginRight: '-20px' }}>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Sign Out" />
                </ItemMenu>

              </Menu>
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <div className="align-items-center">
              <span style={{ fontSize: 16, fontWeight: 'bold', color: '#3A4E85' }}>{value ? 'Close Now?' : 'Open Now?'}</span>
              <Switch
                checked={value}
                onChange={() => onChangeSwitch()}
                name="checked"
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ScheduleIcon style={{ color: 'white' }} />}
              disabled={!value}
              onClick={() => {
                setTypeModal('Operational')
                getDetailUser()
              }}
            >
              <strong>Operational Time</strong>
            </Button>
          </Grid>
        </Toolbar>

      </AppBar>
      <Drawer
        className={sideBar ? classes.drawerActive : classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Grid className={classes.drawerLogo} container justify="center" alignItems="center">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img
              style={{ width: '60%' }}
              src={toAbsoluteUrl("/media/bg/logotfb.png")}
              alt="logo"
            />
            <Typography className="mt-4" style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {`Web Outlet`}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            margin: '0px 10px 5px 0px'
          }}
        >
          <Typography>Version {version}</Typography>
      </Grid>
        {/* Hardcode menu roles */}
        {menu['user'].map((item, key) => <MenuItem key={key} item={item} pathname={pathname} />)}
      </Drawer>
      <main className={classes.content}>
        {/* <Layout> */}
        <BasePage />
        {/* </Layout> */}
      </main>
      <Dialog fullWidth maxWidth="xs" open={openModal} onClose={() => { setOpenModal(false) }} aria-labelledby="form-create">
        {typeModal === 'ChangePassword' &&
          <>
            <DialogTitle id="title">Change Password</DialogTitle>
            <Divider light />
          </>
        }
        <DialogContent>
          {typeModal === 'Operational' &&
            renderOperational()
          }
          {typeModal === 'ChangePassword' &&
            <ChangePassword
              setSignOutId={user?.userId}
              handleCancel={() => setOpenModal(false)}
            />
          }
        </DialogContent>
      </Dialog>
    </div>
  );
}

const MenuItem = ({ item, pathname }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  item.title = item.title.length > 25 ? `${item.title.substr(0, 25)}...` : item.title;
  return <Component item={item} pathname={pathname} />;
};

const SingleLevel = ({ item, pathname }) => {
  const history = useHistory();
  return (
    <ListItem style={pathname === item.to ? { backgroundColor: 'rgba(58, 78, 133, 0.1)', color: '#3A4E85' } : {}} button onClick={() => history.push(item.to)}>
      {pathname === item.to &&
        <div style={{ width: 10, borderTopRightRadius: 5, borderBottomRightRadius: 5, height: '100%', position: 'absolute', left: 0, backgroundColor: '#3A4E85' }}></div>
      }
      <ListItemIcon style={{ alignItems: 'center', justifyContent: 'center' }}>
        {(typeof item.icon) === 'string' ?
          <i style={pathname === item.to ? { color: '#3A4E85' } : {}} className={`fa ${item.icon}`} />
          :
          item.icon(pathname === item.to)
        }
      </ListItemIcon>
      {/* <ListItemIcon style={{ paddingLeft: 12 }}><i style={pathname === item.to ? { color: '#3A4E85' } : {}} className={`fa ${item.icon}`} /></ListItemIcon> */}
      <ListItemText primary={item.title} />
    </ListItem>
  );
};

const MultiLevel = ({ item, pathname }) => {
  const { items: children } = item;
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        {/* <ListItemIcon style={{ paddingLeft: 12 }}><i className={`fa ${item.icon}`} /></ListItemIcon> */}
        <ListItemIcon style={{ alignItems: 'center', justifyContent: 'center' }}>
          {(typeof item.icon) === 'string' ?
            <i className={`fa ${item.icon}`} />
            :
            item.icon()
          }
        </ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} pathname={pathname} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};


const mapStateToProps = (state) => (state)

export default connect(mapStateToProps)(MaterialDrawer)