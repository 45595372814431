export default {
  auth: {
    login: {
      method: 'post',
      url: '/v1/users/login',
    },
    refreshToken: {
      method: 'post',
      url: '/v1/users/refresh-token',
    },
    user: {
      method: 'get',
      url: '/v1/users/me',
    },
    changePassword: {
      method: 'patch',
      url: '/v1/users/change-password',
    },
    forgotPassword: {
      method: 'post',
      url: '/v1/users/forgot-password/:userName',
    },
    resetPassword: {
      method: 'post',
      url: '/v1/users/reset-password',
    },
  },
  menu: {
    list: {
      method: 'get',
      url: '/v1/menus?p=:page&s=:size&sortBy=:sort&order=:order&q=:search',
    },
    detail: {
      method: 'get',
      url: '/v1/menus/:menuId',
    },
    editStock: {
      method: 'patch',
      url: '/v1/menus/edit-stock/:menuId',
    },
    listAddons: {
      method: 'get',
      url: '/v1/menus/addons?p=:page&s=:size&sort=:sort&order=:order&q=:search',
    },
    listCustomItem: {
      method: 'get',
      url: '/v1/menus/product-custom?p=:page&s=:size&q=:search',
    },
    editCustom: {
      method: 'patch',
      url: '/v1/menus/edit-custom/:productCustomizationId',
    },
  },
  order: {
    read: {
      method: 'get',
      url: '/v1/order-outlet/list-order?p=:page&s=:size&sort=:sort&order=:order&q=:search&startDate=:startDate&endDate=:endDate',
    },
    readPickup: {
      method: 'get',
      url: '/v1/order-outlet/order-pickup?p=:page&s=:size&sort=:sort&order=:order&q=:search&startDate=:startDate&endDate=:endDate',
    },
    detail: {
      method: 'get',
      url: '/v1/order-outlet/detail-order/:orderId?fromPickupPoint=:fromPickupPoint',
    },
    orderDetail: {
      method: 'get',
      url: '/v1/order-outlet/detail-order/detail/:orderDetailId',
    },
    history: {
      method: 'get',
      url: '/v1/order-outlet/history?p=:page&s=:size&sort=:sort&order=:order&q=:search&startDate=:startDate&endDate=:endDate',
    },
    approval: {
      method: 'patch',
      url: '/v1/order-outlet/approval',
    },
    readPickupPoint: {
      method: 'get',
      url: '/v1/order-outlet/list-order-pickup-point?p=:page&s=:size&sort=:sort&order=:order&q=:search&startDate=:startDate&endDate=:endDate',
    },
    detailPickup: {
      method: 'get',
      url: '/v1/order-outlet/detail-order-pickup/:orderId?fromPickupPoint=:fromPickupPoint',
    },
    findDriver: {
      method: 'post',
      url: '/v1/order-outlet/finddriver/:orderId',
    },
    pickup: {
      method: 'patch',
      url: '/v1/order-outlet/ready-pickup/:orderId',
    },
    finish: {
      method: 'patch',
      url: '/v1/order-outlet/finish-pickup/:orderId',
    },
  },
  outlet: {
    open: {
      method: 'patch',
      url: '/v1/outlets/open-outlet',
    }
  },
  file: {
    read: {
      method: 'get',
      url: '/v1/file/:fileName',
    }
  },
  notification: {
    sendOrder: {
      method: 'post',
      url: '/v1/notifications/new-order/:orderId',
    },
    readNotif: {
      method: 'patch',
      url: '/v1/order-outlet/notification',
    },
    getNotif: {
      method: 'get',
      url: '/v1/order-outlet/notification?type=:type',
    },
    getSound: {
      method: 'get',
      url: '/v1/order-outlet/notification/new',
    },
  }
}
