/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import { API } from "../../../../redux/setupAxios";
import { Container, Divider, Card, InputLabel, IconButton, Snackbar, Typography, CssBaseline, Button, TextField, Paper, Grid } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SaveIcon from '@material-ui/icons/Save';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function ChangePassword(props) {
  const { userId, handleCancel } = props;
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(
        'Current Password is Required'
      ),
    newPassword: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 50 characters")
      .matches(
        /^(?=.*?[0-9])/,
        "Password must contain 1 number"
      )
      .matches(
        /^(?=.*?[A-Z])/,
        "Password must contain 1 uppercase"
      )
      .matches(
        /^(?=.*?[a-z])/,
        "Password must contain 1 lowercase"
      )
      .matches(
        /^(?=.*?[@$!%^-_=+`~*,.:;#?&])/,
        "Password must contain 1 special character"
      )
      .required(
        'New Password is Required'
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], "Password doesn't match")
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 50 characters")
      .matches(
        /^(?=.*?[0-9])/,
        "Password must contain 1 number"
      )
      .matches(
        /^(?=.*?[A-Z])/,
        "Password must contain 1 uppercase"
      )
      .matches(
        /^(?=.*?[a-z])/,
        "Password must contain 1 lowercase"
      )
      .matches(
        /^(?=.*?[@$!%^-_=+`~*,.:;#?&])/,
        "Password must contain 1 special character"
      )
      .required(
        'Confirm Password is Required'
      ),
  });


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        doChangePassword(values);
      }, 1000);
    },
  });

  const doChangePassword = async (values) => {
    try {
      const response = await API('auth.changePassword', {
        data: values
      })
      if (response.data.isSuccess && response.data.statusCode === 200) {
        Toast.fire({
          icon: 'success',
          title: 'Password successfully changed...'
        })
        handleCancel()
        setLoading(false);
      } else {
        Toast.fire({
          icon: 'error',
          title: response.data?.payload?.Password?.errorMessage[0]
        })
      }
      setLoading(false);
    } catch {
      Toast.fire({
        icon: 'error',
        title: 'Something wrong!'
      })
      setLoading(false);
    }
  }

  return (
    <Container className="mt-8 pb-12">
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container className="pb-4">
          <Grid item xs={1} />
          <Grid item xs={9}>
            <TextField
              autoFocus
              required
              fullWidth
              name="currentPassword"
              type="text"
              label="Current Password"
              id="currentPassword"
              autoComplete="current-password"
              {...formik.getFieldProps("currentPassword")}
              className={getInputClasses(
                "currentPassword"
              )}
            />
            {formik.touched.currentPassword && formik.errors.currentPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block" style={{ color: 'red' }}>*{formik.errors.currentPassword}</div>
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Grid container className="pb-4">
          <Grid item xs={1} />
          <Grid item xs={9}>
            <TextField
              required
              fullWidth
              name="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              label="New Password"
              id="newPassword"
              autoComplete="current-password"
              {...formik.getFieldProps("newPassword")}
              className={getInputClasses(
                "newPassword"
              )}
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block" style={{ color: 'red' }}>*{formik.errors.newPassword}</div>
              </div>
            ) : null}
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className="btn-block"
              aria-label="toggle password visibility"
              style={{ marginTop: 10 }}
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Grid>
        </Grid>
        <Grid container className="pb-4">
          <Grid item xs={1} />
          <Grid item xs={9}>
            <TextField
              required
              fullWidth
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              label="Confirm Password"
              id="confirmPassword"
              autoComplete="current-password"
              {...formik.getFieldProps("confirmPassword")}
              className={getInputClasses(
                "confirmPassword"
              )}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block" style={{ color: 'red' }}>*{formik.errors.confirmPassword}</div>
              </div>
            ) : null}
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className="btn-block"
              aria-label="toggle password visibility"
              style={{ marginTop: 10 }}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Grid>
        </Grid>
        {/* <Divider  light className="m-8" /> */}
        <Grid container justify="flex-end" alignItems="center">
          <Grid item className="p-2">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={3} className="p-2">
            <Button
              // className="btn-block"
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={loading}
            >
              Save
              {loading && <span className="ml-3 mr-3 spinner spinner-white"></span>}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default ChangePassword;
