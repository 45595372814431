import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { API } from "../../../../redux/setupAxios";
import { IconButton, Snackbar, Typography, CssBaseline, Paper, Grid } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Form, Button } from "react-bootstrap"
import { version } from '../../../../../package.json';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundImage: `linear-gradient(rgba(255,255,255, 0.7), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), 
              url('${toAbsoluteUrl(
      "/media/bg/background.png"
    )}')`,
    backgroundSize: 'cover',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 26
  },
}));

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { dispatch, state } = props;
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [notif, setOpenNotif] = React.useState({
    open: false,
    type: '',
    message: ''
  });
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required(
        'Email tidak boleh kosong'
      ),
    password: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 50 characters")
      .required(
        'Password tidak boleh kosong'
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        doLogin(values);
      }, 1000);
    },
  });

  const doLogin = async (values) => {
    try {
      const payload = {
        email: values.email,
        password: values.password
      }
      const response = await API('auth.login', {
        data: payload,
      })
      if (response.data.isSuccess && response.data.statusCode === 200) {
        dispatch({
          type: 'LOGIN',
          payload: {
            ...state.auth,
            scheme: response?.data?.payload?.scheme || null,
            authToken: response?.data?.payload?.token || null,
            refreshToken: response?.data?.payload?.refreshToken || null,
            refreshTokenExpiredOn: response?.data?.payload?.refreshTokenExpiredOn || null,
          }
        })
        // localStorage.setItem('notification', [])
        getDetailUser()
      } else {
        setOpenNotif({
          ...notif,
          open: true,
          type: 'error',
          message: response.data.message
        })
        setLoading(false);
      }
      setLoading(false);
    } catch {
      setOpenNotif({
        ...notif,
        open: true,
        type: 'error',
        message: 'Something wrong!'
      })
      setLoading(false);
    }
  }

  const getDetailUser = async () => {
    try {
      const response = await API('auth.user')
      if (response.data.isSuccess && response.data.statusCode === 200) {
        dispatch({
          type: 'SET_USER',
          payload: {
            ...state.auth,
            user: response?.data?.payload || null
          }
        })
        setLoading(false);
      } else {
        setOpenNotif({
          ...notif,
          open: true,
          type: 'error',
          message: response.data.message
        })
        setLoading(false);
      }
    } catch {
      setOpenNotif({
        ...notif,
        open: true,
        type: 'error',
        message: 'Something wrong!'
      })
      setLoading(false);
    }
  }

  const handleForgotPassword = () => {
    history.push('/auth/forgot-password')
    // Swal.fire({
    //   icon: 'info',
    //   text: 'Please contact Super Admin to reset password',
    //   width: '350px',
    //   timer: 2000,
    // })
  }

  return (
    <Grid container component="main" className={classes.root} justify="center" alignItems="center">
      <CssBaseline />
      <Grid item xs={12} sm={6} md={3} component={Paper} elevation={6} style={{ borderRadius: 36 }}>
        <div className={classes.paper}>
          {(formik.errors.email || formik.errors.password) ?
            <div className="mb-3 p-3 alert alert-custom alert-light-danger">
              <div className="alert-text font-weight-bold">{formik.errors.email || formik.errors.password}</div>
            </div>
            : notif && notif?.open ?
              <>
                <Alert onClose={() => setOpenNotif({ ...notif, open: false })} severity={notif.type}>{notif.message}</Alert>
                <br />
              </>
              :
              <div style={{ height: 48 }} />
          }
          <img
            style={{ width: '45%', objectFit: 'cover' }}
            src={toAbsoluteUrl("/media/bg/logotfb.png")}
            alt="logo"
          />
          <br />
          <Typography>
            {`Sign in your outlet account`}
          </Typography>
          <br />
          <Form
            style={{ width: '100%' }}
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                style={{ borderRadius: 30, height: 42, borderColor: '#F0F0F0', backgroundColor: '#F0F0F0' }}
                type="email"
                placeholder="your email.."
                {...formik.getFieldProps("email")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Password</Form.Label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Control
                  style={{ borderRadius: 30, height: 42, borderColor: '#F0F0F0', backgroundColor: '#F0F0F0' }}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="your password.."
                  {...formik.getFieldProps("password")}
                />
                <IconButton
                  style={{ marginLeft: 10 }}
                  size="small"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ?
                    <Visibility style={{ fontSize: 25, color: '#8E8E93' }} /> :
                    <VisibilityOff style={{ fontSize: 25, color: '#8E8E93' }} />}
                </IconButton>
              </div>
            </Form.Group>
            <Button
              type="submit"
              disabled={formik.errors.email || formik.errors.password}
              style={{ marginTop: 20, backgroundColor: '#3A4E85', width: '100%', height: 40, borderRadius: 30 }}
            >
              <strong className="text-white">Sign in</strong>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </Button>
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
              <span>
                <div onClick={() => handleForgotPassword()}>
                  <strong style={{ color: '#3A4E85', cursor: 'pointer' }}>Forgot Password ?</strong>
                </div>
              </span>
            </div>
          </Form>
        </div>
      </Grid>
      <Grid
        item
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          margin: '0px 10px 5px 0px'
        }}
      >
        <Typography>Version {version}</Typography>
      </Grid>
    </Grid >
  );
}

const mapStateProps = (state) => ({ state })

export default injectIntl(connect(mapStateProps)(Login));
