import React from 'react';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";

export const menu = {
    admin: [
        {
            "icon": "fa-home",
            "title": "Dashboard",
            "to": "/dashboard",
            "items": []
        },
        {
            "icon": "fa-book",
            "title": "Material UI",
            "items": [
                {
                    "title": "Inputs",
                    "items": [
                        {
                            "title": "Autocomplete",
                            "to": "/google-material/inputs/autocomplete"
                        },
                        {
                            "title": "Buttons",
                            "to": "/google-material/inputs/buttons"
                        },
                        {
                            "title": "Checkboxes",
                            "to": "/google-material/inputs/checkboxes"
                        },
                    ]
                },
            ]
        },
        {
            "icon": "fa-check",
            "title": "Bootstrap",
            "items": [
                {
                    "title": "Alert",
                    "to": "/react-bootstrap/alert"
                },
                {
                    "title": "Badge",
                    "to": "/react-bootstrap/badge"
                },
            ]
        },
        {
            "icon": "fa-cubes",
            "title": "Custom",
            "items": [
                {
                    "title": "eCommerce",
                    "items": [
                        {
                            "title": "Customers",
                            "to": "/e-commerce/customers"
                        },
                        {
                            "title": "Products",
                            "to": "/e-commerce/products"
                        },
                    ]
                },
                {
                    "title": "Layout Builder",
                    "to": "/builder"
                },
            ]
        },
    ],
    user: [
        {
            "icon": () => {
                return (
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/Subtract.svg"
                        )}
                        className="h-50 align-self-center"
                    />
                )
            },
            "title": "Pengelolaan Menu & Stok",
            "to": "/menu-and-stok",
            "items": [
                // {
                //     "title": "Current Menu & Stok",
                //     "to": "/current-menu",
                //     "icon": ""
                // },
                // {
                //     "title": "Edit Menu & Stok",
                //     "to": "/edit-menu",
                //     "icon": ""
                // },
            ]
        },
        {
            "icon": () => {
                return (
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/menu.svg"
                        )}
                        className="h-50 align-self-center"
                    />
                )
            },
            "title": "Pengelolaan Pembelian",
            "items": [
                {
                    "title": "Daftar Pesanan Delivery",
                    "to": "/daftar-pesanan",
                    "icon": ""
                },
                {
                    "title": "Daftar Pesanan Pickup",
                    "to": "/daftar-pesanan-pickup",
                    "icon": ""
                },
                {
                    "title": "Riwayat Pesanan",
                    "to": "/histori-pesanan",
                    "icon": ""
                },
                {
                    "title": "Daftar Pickup Point",
                    "to": "/pickup-point",
                    "icon": ""
                },
            ]
        },
        // {
        //     "icon": () => {
        //         return (
        //             <SVG
        //                 src={toAbsoluteUrl(
        //                     "/media/svg/menu.svg"
        //                 )}
        //                 className="h-50 align-self-center"
        //             />
        //         )
        //     },
        //     "title": "Pengelolaan Menu",
        //     "to": "/pengelolaan-menu",
        //     "items": []
        // },
        // {
        //     "icon": () => {
        //         return (
        //             <SVG
        //                 src={toAbsoluteUrl(
        //                     "/media/svg/Subtract.svg"
        //                 )}
        //                 className="h-50 align-self-center"
        //             />
        //         )
        //     },
        //     "title": "Pengelolaan Stok",
        //     "to": "/pengelolaan-stok",
        //     "items": []
        // },
    ],
    guest: [
        {
            "icon": "fa-home",
            "title": "Dashboard",
            "to": "/dashboard",
            "items": []
        },
    ]
}