import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
// WEB OUTLET
// import { DaftarPesanan } from "./pages/daftar-pesanan";
// import { HistoriPesanan } from "./pages/histori-pesanan";
// import { PengelolaanMenu } from "./pages/pengelolaan-menu";
// import { PengelolaanStok } from "./pages/pengelolaan-stok";
// import { PickupPoint } from "./pages/pickup-point";
// import { Notifikasi } from "./pages/notifikasi";

const DaftarPesanan = lazy(() =>
  import("./pages/daftar-pesanan")
);
const DaftarPesananPickup = lazy(() =>
  import("./pages/daftar-pesanan-pickup")
);
const HistoriPesanan = lazy(() =>
  import(".//pages/histori-pesanan")
);
const PengelolaanMenu = lazy(() =>
  import("./pages/pengelolaan-menu")
);
const PengelolaanStok = lazy(() =>
  import("./pages/pengelolaan-stok")
);
const PickupPoint = lazy(() =>
  import("./pages/pickup-point")
);
const Notifikasi = lazy(() =>
  import("./pages/notifikasi")
);

function BasePage({ auth }) {
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /home. */
          <Redirect exact from="/" to="/menu-and-stok" />
        }
        {
          <Redirect exact from="/notify" to="/daftar-pesanan" />
        }
        {
          <Redirect exact from="/notify-pickup" to="/daftar-pesanan-pickup" />
        }
        {
          <Redirect exact from="/notify-histori" to="/histori-pesanan" />
        }
        {/* WEB OUTLET */}
        <ContentRoute path="/daftar-pesanan" component={DaftarPesanan} />
        <ContentRoute path="/daftar-pesanan-pickup" component={DaftarPesananPickup} />
        <ContentRoute path="/histori-pesanan" component={HistoriPesanan} />
        <ContentRoute path="/pengelolaan-menu" component={PengelolaanMenu} />
        <ContentRoute path="/menu-and-stok" component={PengelolaanStok} />
        <ContentRoute path="/current-menu" component={PengelolaanStok} />
        <ContentRoute path="/edit-menu" component={PengelolaanStok} />
        <ContentRoute path="/pickup-point" component={PickupPoint} />
        <ContentRoute path="/notifikasi" component={Notifikasi} />
        <Redirect to="error/error-v5" />
      </Switch>
    </Suspense>
  );
}

const mapStateProps = (state) => (state)

export default connect(mapStateProps)(BasePage)