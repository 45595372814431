import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Howl } from 'howler'

// let audio = new Audio(toAbsoluteUrl("/media/notify.wav"))
let audio = new Howl({
    src: [toAbsoluteUrl("/media/ringtone.mp3")],
    autoplay: false,
});
let ref = setInterval(() => { }, 0)

export const colorStatus = (status) => {
    switch (status) {
        case 'OnProcess':
            return 'rgba(246, 148, 32, 0.1)'
        case 'Processing':
            return 'rgba(246, 148, 32, 0.1)'
        case 'Delivering':
            return 'rgba(62, 160, 254, 0.35)'
        case 'Success':
            return 'rgba(90, 168, 89, 0.1)'
        case 'Rejected':
            return 'rgba(240, 84, 84, 0.1)'
        case 'Cancelled':
            return 'rgba(240, 84, 84, 0.1)'
        default:
            return 'rgba(232, 236, 241, 1)'
    }
}

export const textColorStatus = (status) => {
    switch (status) {
        case 'OnProcess':
            return '#F69420'
        case 'Processing':
            return '#F69420'
        case 'Delivering':
            return '#47A6FF'
        case 'Success':
            return '#5AA859'
        case 'Rejected':
            return '#F05454'
        case 'Cancelled':
            return '#F05454'
        default:
            return 'rgba(103, 128, 159, 1)'
    }
}

export const soundPlay = () => {
    audio.stop()
    audio.play()
    // ref = setInterval(() => {
    //     audio.stop()
    //     audio.play()
    // }, 10000);
    // return () => clearInterval(ref);
}

export const soundStop = () => {
    // clearInterval(ref)
    audio.stop()
}
