import React from "react";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
   {
    // direction: "rtl",
    typography: {
      fontFamily: ["Poppins"].join(",")
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#3A4E85',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: '#FEA434',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#F05454',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    }
  },
  {
    overrides: {
      MuiOutlinedInput: {
        root: {
          borderRadius: 10
        }
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: 0
        }
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          borderRight: 'none'
        }
      },
      MuiAppBar: {
        colorPrimary: {
          color: 'black',
          backgroundColor: '#fafafa'
        }
      },
      MuiPaper: {
        elevation4: {
          boxShadow: 'none',
        }
      },
      // Data Table
      MuiTableHead: {
        root: {
          backgroundColor: '#3A4E85'
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: 'none'
        },
        head: {
          color: 'white',
        }
      },
      MuiTableSortLabel: {
        root: {
          '&:hover': {
            color: '#f2f2f2'
          },
          '&:focus': {
            color: 'white'
          }
        },
      }
    }
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
