import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
  Login: "LOGIN",
  Logout: "LOGOUT",
  SetUser: "SET_USER",
  Notification: "NOTIFICATION",
};

const initialAuthState = {
  // Default payload login
  scheme: undefined,
  authToken: undefined,
  refreshToken: undefined,
  refreshTokenExpiredOn: undefined,
  // Default payload get user
  user: undefined,
  notification: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "TFBBUS-Auth",
    whitelist: [
      "scheme",
      "authToken",
      "refreshToken",
      "refreshTokenExpiredOn",
      "user",
      "notification"
    ]
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { scheme, authToken, refreshToken, refreshTokenExpiredOn } = action.payload;

        return { ...state, scheme, authToken, refreshToken, refreshTokenExpiredOn };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.Notification: {
        const { notification } = action.payload;
        return { ...state, notification };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setNotification: (notification) => ({ type: actionTypes.Notification, payload: { notification } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.setUser());
    yield put(actions.setNotification());
  });
}
