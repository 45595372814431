import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import Swal from "sweetalert2";
import { hubConnection } from '../modules/Function/notification';

function IdleTime(props) {
    const { auth } = props
    const { user } = auth
    const idleTimerRef = useRef(null)
    const history = useHistory();

    const onIdle = () => {
        history.push('/logout')
        Swal.fire({
            title: 'Info',
            text: 'You are idle for 30 minutes',
            confirmButtonText: "Yes",
        })
    }

    useEffect(() => {
        const connect = hubConnection()
        connect.start().then(() => {
            connect.send('AddToOutlet', user?.outletId)
        }).catch((error) => {
            console.log('Error Notification', error)
        });
    }, []);

    return (
        <div>
            <IdleTimer ref={idleTimerRef} timeout={1800 * 1000} onIdle={onIdle}></IdleTimer>
        </div>
    );
};


const mapStateProps = (state) => ({ auth: state.auth })

export default connect(mapStateProps)(IdleTime);
