// export default function setupAxios(axios, store) {
//   axios.interceptors.request.use(
//     config => {
//       const {
//         auth: { authToken }
//       } = store.getState();

//       if (authToken) {
//         config.headers.Authorization = `Bearer ${authToken}`;
//       }

//       return config;
//     },
//     err => Promise.reject(err)
//   );
// }

import axios from 'axios';
import store from './store';
import endpoint from './endpoints';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
})
apiClient.interceptors.request.use(
  config => {
    const {
      auth: { authToken }
    } = store.getState();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  err => Promise.reject(err)
);

const urlBuilder = ({ query, urlApi }) => {
  if (query)
    return Object.keys(query).reduce((url, key) => url.replace(`:${key}`, query[key]), urlApi)
  return urlApi
}

export const API = (...args) => {
  const [urlMethod, params] = args
  const [name, method] = urlMethod.split('.')

  const context = { ...endpoint[name][method], ...params }
  context.url = urlBuilder({ ...params, urlApi: endpoint[name][method].url })

  return apiClient(context)
}